<template>
  <power-bi
    code="eyJrIjoiYjFjMGMwMjMtMzlhYi00NjkxLThiYzQtNzg5ZjU2ZDg4MjVjIiwidCI6ImM4NDJjMWQ5LWQzNjQtNDcxNi1iN2UzLWNhNDgxYTIzZDZhYyJ9"
  />
</template>

<script>
import PowerBi from '../components/PowerBi.vue';

export default {
    components: {
        PowerBi,
    },
};
</script>
